import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: { title: 'Nuevo Consumo' },
    path: '/',
    name: 'home',
    component: Home
  },
  {
    meta: { title: 'Selecciona Local' },
    path: '/select-local',
    name: 'select-local',
    component: () => import(/* webpackChunkName: "select local" */ '../views/SelectLocal.vue')
  },
  {
    meta: { title: 'Delivery' },
    path: '/delivery',
    name: 'delivery',
    component: () => import(/* webpackChunkName: "delivery" */ '../views/Delivery/List.vue')
  },
  {
    meta: { title: 'Detalle Delivery' },
    path: '/delivery/:id',
    name: 'delivery-detail',
    component: () => import(/* webpackChunkName: "delivery" */ '../views/Delivery/Detail.vue')
  },
  {
    meta: { title: 'Productos' },
    path: '/deals',
    name: 'deals',
    component: () => import(/* webpackChunkName: "deals" */ '../views/Product/List.vue')
  },
  {
    meta: { title: 'Añadir Producto' },
    path: '/deals/add',
    name: 'deals-add',
    component: () => import(/* webpackChunkName: "deals" */ '../views/Product/Add.vue')
  },
  {
    meta: { title: 'Actualizar Producto' },
    path: '/deals/detail/:id',
    name: 'deals-detail',
    component: () => import(/* webpackChunkName: "deals" */ '../views/Product/Detail.vue')
  },
  {
    meta: { title: 'Facturación' },
    path: '/facturacion',
    name: 'billing',
    component: () => import(/* webpackChunkName: "billing" */ '../views/Billing/List.vue')
  },
  {
    meta: { title: 'Ordenes' },
    path: '/consumo',
    name: 'order',
    component: () => import(/* webpackChunkName: "order" */ '../views/Order/List.vue')
  },
  {
    meta: { title: 'Dashboard' },
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  },
  {
    meta: { title: 'Ayuda' },
    path: '/ayuda',
    name: 'help',
    component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue')
  },
  {
    meta: { title: 'Mi Perfil' },
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
  },
  {
    meta: { title: 'Inicia Sesión' },
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    meta: { title: 'Error' },
    path: '/error',
    component: () =>
      import(/* webpackChunkName: "error" */ '../views/Error.vue')
  },
  { path: '*', redirect: '/error' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !Vue.localStorage.get('token')) {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
