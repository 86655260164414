<template>
  <div class="column">
    <card-component title="Selecciona un local" icon="store">
      <div class="columns">
        <b-field class="column is-one-third">
          <b-select
            expanded
            placeholder="Elige una opción"
            v-model="local"
            @input="saveLocal"
          >
            <option
              v-for="localItem in localList"
              :value="localItem.id"
              :key="localItem.id">
              {{ localItem.name }}
            </option>
          </b-select>
        </b-field>
      </div>
    </card-component>
    <card-component
      title="Selecciona un programa de beneficios"
      icon="view-list"
    >
      <company-provider-skeleton v-if="isLoading" />
      <div class="columns is-flex-wrap-wrap" v-else>
        <div
          class="column is-one-third"
          v-for="company in results"
          :key="company.id"
        >
          <card-component
            class="is-clickable has-table has-mobile-sort-spaced"
            @click.native="chooseOption(company)"
          >
            <div class="columns is-align-items-center">
              <div class="column">
                <figure class="image is-64x64">
                  <img :src="company.image_logo_crop" class="card" />
                </figure>
              </div>
              <div class="column">
                <span class="has-text-weight-medium">{{ company.name.toUpperCase() }}</span>
              </div>
            </div>
          </card-component>
        </div>
      </div>
    </card-component>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CardComponent from '@/components/CardComponent'
import CompanyService from '@/services/CompanyService'
import CompanyProviderSkeleton from '@/components/Validate/Skeleton/CompanyProviderSkeleton'
export default {
  components: {
    CardComponent,
    CompanyProviderSkeleton
  },
  data () {
    return {
      option: null,
      results: [],
      isLoading: false,
      local: this.$localStorage.get('local')
    }
  },
  computed: {
    ...mapState(['localList'])
  },
  created () {
    this.getCompanyList()
  },
  methods: {
    errorMessage () {
      this.$buefy.toast.open({
        message: 'Ocurrió un error.',
        type: 'is-danger'
      })
    },
    async getCompanyList () {
      this.isLoading = true
      try {
        const response = await CompanyService.getCompanyList()
        this.results = response.data
      } catch (error) {
        this.errorMessage()
      }
      this.isLoading = false
    },
    chooseOption (company) {
      this.$emit('update', { step: 1, option: company.id, logo: company.image_logo_crop })
    },
    saveLocal () {
      this.$store.dispatch('saveLocal', this.local)
    }
  }
}
</script>
