<template>
  <Layout>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section is-centered columns">
      <company-provider-list
        v-if="step === 0"
        @update="updateView"
      />
      <document-form
        v-else-if="step === 1"
        :option="option"
        @goBack="goBack"
        @validateDocument="validateDocument"
        :logo="logo"
      />
      <unregistered-user
        v-else-if="step === 2"
        :document="document"
        @goBack="goBack"
      />
      <register-user
        v-else-if="step === 3"
        :option="option"
        :document="document"
        @goBack="goBack"
        :logo="logo"
      />
      <product-list
        v-else-if="step === 4"
        :option="option"
        :document="document"
        @goBack="goBack"
        @validateProduct="validateProduct"
      />
      <validate-form
        v-else-if="step === 5"
        :option="option"
        :document="document"
        :idProduct="idProduct"
        @goBack="goBack"
      />
      <thanks-validate
        v-else-if="step === 6"
        :document="document"
        @goBack="goBack"
      />
    </section>
  </Layout>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import Layout from '@/components/Layout'
import CompanyProviderList from '@/components/Validate/CompanyProviderList'
import DocumentForm from '@/components/Validate/DocumentForm'
import UnregisteredUser from '@/components/Validate/UnregisteredUser'
import RegisterUser from '@/components/Validate/RegisterUser'
import ProductList from '@/components/Validate/ProductList'
import ValidateForm from '@/components/Validate/ValidateForm'
import ThanksValidate from '@/components/Validate/ThanksValidate'
export default {
  name: 'Home',
  components: {
    TitleBar,
    Layout,
    CompanyProviderList,
    DocumentForm,
    UnregisteredUser,
    RegisterUser,
    ProductList,
    ValidateForm,
    ThanksValidate
  },
  data () {
    return {
      isLoading: false,
      document: null,
      step: 0,
      option: null,
      idProduct: null,
      logo: null
    }
  },
  computed: {
    titleStack () {
      return [
        'Inicio',
        'Nuevo Consumo'
      ]
    }
  },
  methods: {
    goBack (step) {
      this.step = step
    },
    updateView (data) {
      this.step = data.step
      this.option = data.option
      this.logo = data.logo
    },
    validateProduct (data) {
      this.step = data.step
      this.idProduct = data.idProduct
    },
    validateDocument (data) {
      this.document = data.document
      if (data.response === 'new') {
        this.step = 2
      } else if (data.response === 'include_register') {
        this.step = 3
      } else if (data.response === 'success') {
        this.step = 4
      }
    }
  }
}
</script>
