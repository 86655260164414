<template>
  <section>
    <nav-bar/>
    <aside-menu :menu="menu" />
    <div class="my-body">
      <slot />
    </div>
  </section>
</template>

<script>
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
export default {
  components: {
    AsideMenu,
    NavBar
  },
  computed: {
    menu () {
      return [
        'Inicio',
        [
          {
            to: '/',
            icon: 'book-plus',
            label: 'Nuevo Consumo'
          },
          {
            to: '/delivery',
            label: 'Pedido Delivery',
            icon: 'cart'
          },
          {
            to: '/deals',
            label: 'Promociones',
            icon: 'percent-outline'
          }
        ],
        'Reportes',
        [
          {
            to: '/dashboard',
            icon: 'desktop-mac',
            label: 'Dashboard'
          },
          {
            to: '/consumo',
            label: 'Historial',
            icon: 'calendar-range'
          },
          {
            to: '/facturacion',
            label: 'Facturas',
            icon: 'file'
          }
          // {
          //   label: 'Submenus',
          //   subLabel: 'Submenus Example',
          //   icon: 'view-list',
          //   menu: [
          //     {
          //       href: '#void',
          //       label: 'Sub-item One'
          //     }
          //   ]
          // }
        ],
        'Soporte',
        [
          {
            to: '/ayuda',
            label: 'Ayuda',
            icon: 'help-circle'
          },
          {
            href: 'https://api.whatsapp.com/send?phone=51989271936&text=Hola,%20tengo%20una%20consulta',
            label: 'Whatsapp',
            icon: 'whatsapp'
          }
        ]
      ]
    }
  },
  created () {
    if (
      this.$localStorage.get('local')
    ) {
      const name = this.$localStorage.get('company_name')
      const email = this.$localStorage.get('email')
      const avatar =
        this.$localStorage.get('company_logo')
          ? this.$localStorage.get('company_logo')
          : `https://avatars.dicebear.com/api/initials/${name}.svg`
      this.$store.commit('user', {
        name,
        email,
        avatar
      })
      this.getLocalList()
    } else {
      this.$router.push({ name: 'select-local' })
    }
  },
  methods: {
    getLocalList () {
      this.$store.dispatch('getLocalList')
    }
  }
}
</script>
