<template>
  <section class="has-text-centered column is-two-fifths">
    <card-component
      title="Validación exitosa"
      icon="check"
    >
      <span class="has-text-weight-medium">
        La validación se ha registrado con éxito
      </span>
      <p>
        N° Documento:
        <span class="has-text-weight-medium">
          {{ document }}
        </span>
      </p>
      <hr>
      <b-button class="is-primary ml-2" @click.native="goBack">
        Volver al inicio
      </b-button>
    </card-component>
  </section>
</template>

<script>
import CardComponent from '@/components/CardComponent'
export default {
  components: {
    CardComponent
  },
  props: {
    document: String
  },
  methods: {
    goBack () {
      this.$emit('goBack', 0)
    }
  }
}
</script>
