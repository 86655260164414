import axios from 'axios'
class CompanyService {
  getCompanyList () {
    const response = axios.get('company-provider-list/')
    return response
  }
}

const instance = new CompanyService()
export default instance
