<template>
  <div>
    <card-component
      v-for="(item, index) in 3"
      :key="index"
      class="m-3"
    >
      <div class="columns is-align-items-center" style="gap: 0.5rem">
        <div class="column">
          <b-skeleton width="190px" height="130px"></b-skeleton>
        </div>
        <div class="column is-four-fifths">
          <b-skeleton width="40%"></b-skeleton>
          <b-skeleton width="80%"></b-skeleton>
          <b-skeleton width="90%"></b-skeleton>
          <b-skeleton width="60%"></b-skeleton>
        </div>
      </div>
    </card-component>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
export default {
  components: {
    CardComponent
  }
}
</script>
