var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"is-two-fifths column"},[_c('card-component',{attrs:{"title":"Ingrese los siguientes datos","icon":"card-text","image":_vm.logo}},[_c('b-field',{attrs:{"label":"Email (opcional)","type":{ 'is-danger': _vm.$v.data.email.$error },"message":[
        {
          'Requerido. Ingresa tu email':
            !_vm.$v.data.email.required && _vm.$v.data.email.$error
        },
        {
          'Incorrecto. Ingresa un email válido': !_vm.$v.data.email.email && _vm.$v.data.email.$error
        }
      ]}},[_c('b-input',{attrs:{"placeholder":"Ingresa tu email","name":"email"},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}})],1),_c('b-field',{attrs:{"label":"Nombres","type":{ 'is-danger': _vm.$v.data.first_name.$error },"message":[
        {
          'Requerido. Ingresa tu nombre':
            !_vm.$v.data.first_name.required && _vm.$v.data.first_name.$error
        }
      ]}},[_c('b-input',{attrs:{"placeholder":"Ingresa tu nombre","name":"first_name"},model:{value:(_vm.data.first_name),callback:function ($$v) {_vm.$set(_vm.data, "first_name", $$v)},expression:"data.first_name"}})],1),_c('b-field',{attrs:{"label":"Apellidos","type":{ 'is-danger': _vm.$v.data.last_name.$error },"message":[
        {
          'Requerido. Ingresa tu apellido':
            !_vm.$v.data.last_name.required && _vm.$v.data.last_name.$error
        }
      ]}},[_c('b-input',{attrs:{"placeholder":"Ingresa tu apellido","name":"last_name"},model:{value:(_vm.data.last_name),callback:function ($$v) {_vm.$set(_vm.data, "last_name", $$v)},expression:"data.last_name"}})],1),_c('b-field',{attrs:{"label":"Documento"}},[_c('b-input',{attrs:{"value":_vm.document,"disabled":""}})],1),_c('hr'),_c('b-button',{staticClass:"is-primary",class:{'is-loading':_vm.isLoading},nativeOn:{"click":function($event){return _vm.validateDocument.apply(null, arguments)}}},[_vm._v(" Continuar ")]),_c('b-button',{staticClass:"is-dark ml-2",nativeOn:{"click":function($event){return _vm.goBack.apply(null, arguments)}}},[_vm._v(" Regresar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }