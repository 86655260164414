export default {
  /* A fit-them-all commit */
  basic (state, payload) {
    state[payload.key] = payload.value
  },
  /* User */
  user (state, payload) {
    if (payload.name) {
      state.userName = payload.name
    }
    if (payload.email) {
      state.userEmail = payload.email
    }
    if (payload.avatar) {
      state.userAvatar = payload.avatar
    }
  },
  /* Aside Mobile */
  asideMobileStateToggle (state, payload = null) {
    const htmlClassName = 'has-aside-mobile-expanded'
    let isShow
    if (payload !== null) {
      isShow = payload
    } else {
      isShow = !state.isAsideMobileExpanded
    }
    if (isShow) {
      document.documentElement.classList.add(htmlClassName)
    } else {
      document.documentElement.classList.remove(htmlClassName)
    }
    state.isAsideMobileExpanded = isShow
  },

  /* Dark Mode */
  darkModeToggle (state, payload) {
    const htmlClassName = 'is-dark-mode-active'
    state.isDarkModeActive = payload
    if (state.isDarkModeActive) {
      document.documentElement.classList.add(htmlClassName)
    } else {
      document.documentElement.classList.remove(htmlClassName)
    }
  },
  // Set Local List
  setLocalList (state, payload) {
    state.localList = payload
  },
  // Set Current Local
  setLocal (state, payload) {
    state.local = payload
  },
  // Set Current Local Name
  setLocalName (state, payload) {
    state.localName = payload
  }
}
