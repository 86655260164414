import Vue from 'vue'

export default function setupAxios () {
  Vue.axios.interceptors.request.use(
    (config) => {
      const token = Vue.localStorage.get('token')
      if (token) {
        config.headers.common.Authorization = 'JWT ' + token
      }
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  Vue.axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      const originalRequest = error.config
      if (
        (error.response.status === 401 && !originalRequest._retry) ||
        (error.response.data.detail === 'Signature has expired.' &&
          error.response.status === 403) ||
        (error.response.data.detail === 'Invalid signature.' &&
          error.response.status === 403) ||
        error.response.status === 401
      ) {
        Vue.localStorage.remove('token')
        Vue.localStorage.remove('email')
        Vue.localStorage.remove('name')
        Vue.localStorage.remove('company_name')
        Vue.localStorage.remove('local')
        window.location.href = '/'

        // originalRequest._retry = true
        // return Vue.axios.post('/security/token-refresh/', {'token': token}).then((result) => {
        //   setTokenData(result.data.token)
        //   originalRequest.headers['Authorization'] = 'JWT ' + result.data.token
        //   return Vue.axios(originalRequest)
        // })
        // .catch((error) => {
        //   console.log('errors', error.response.data.errors)
        //   authLogout()
        //   window.location.href = '/'
        // })
      }
      // if (error.response.status === 404 && !originalRequest._retry) {
      //   originalRequest._retry = true
      //   authLogout()
      //   window.location.href = '/'
      //   return
      // }
      return Promise.reject(error)
    }
  )
}
