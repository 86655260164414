<template>
  <div class="card">
    <div class="card-header" v-if="image">
      <div class="has-text-centered py-2 is-full">
        <img
          :src="image"
          alt="Company Image"
          class="card"
          style="margin: 0 auto; height: 100%"
        />
      </div>
    </div>
    <header v-if="title" class="card-header">
      <p class="card-header-title has-text-weight-medium">
        <b-icon v-if="icon" :icon="icon" custom-size="default"/>
        {{ title }}
      </p>
      <a v-if="headerIcon" href="#" class="card-header-icon" aria-label="more options" @click.prevent="headerIconClick">
        <b-icon :icon="headerIcon" custom-size="default"/>
      </a>
    </header>
    <div class="card-content px-3 py-5">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardComponent',
  props: {
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    headerIcon: {
      type: String,
      default: null
    },
    image: {
      type: String,
      default: ''
    }
  },
  methods: {
    headerIconClick () {
      this.$emit('header-icon-click')
    }
  }
}
</script>
