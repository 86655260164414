<template>
  <section class="column is-two-fifths">
    <card-component
      :title="title"
      icon="account-check"
    >
      <form v-if="type_discount == 1 || type_discount == 2" >
        <b-field
          label="Monto en soles S/."
          :type="{ 'is-danger': $v.mount.$error }"
          :message="[
            {
              'Requerido. Ingresa un monto válido':
                !$v.mount.required && $v.mount.$error
            },
            {
              'Incorrecto. Ingresa un monto válido':
                !$v.mount.decimal && $v.mount.$error
            }
          ]"
        >
          <b-input
            v-model="mount"
            placeholder="0"
            type="numeric"
            name="mount"
            @keyup.native="getCalculateMount"
          />
        </b-field>
        <b-field label="Total a pagar">
          S/. {{ discount.toFixed(2) }}
        </b-field>
      </form>
      <form v-else-if="type_discount == 3">
        <div class="has-text-centered mt-3">
          <b-icon icon="gift" size="is-large" />
        </div>
      </form>
      <form v-else-if="type_discount == 5">
        <div class="has-text-centered mt-3">
          <b-icon icon="cart-plus" size="is-large" />
        </div>
      </form>
      <hr>
      <b-button :loading="isLoading" class="is-primary ml-2" @click.native="postValidateMount">
        Validar
      </b-button>
      <b-button class="is-dark ml-2" @click.native="goBack">
        Regresar
      </b-button>
    </card-component>
  </section>
</template>

<script>
import { required, decimal } from 'vuelidate/lib/validators'
import CardComponent from '@/components/CardComponent'
import ValidateService from '@/services/ValidateService'
export default {
  components: {
    CardComponent
  },
  props: {
    document: String,
    option: Number,
    idProduct: Number
  },
  data () {
    return {
      local: this.$localStorage.get('local'),
      mount: '',
      type_discount: null,
      discount: 0,
      isLoading: false,
      title: ''
    }
  },
  validations: {
    mount: { required, decimal }
  },
  created () {
    this.getValidateTypeProduct()
  },
  methods: {
    goBack () {
      this.$emit('goBack', 4)
    },
    async getValidateTypeProduct () {
      try {
        const response = await ValidateService.getValidateTypeProduct({
          product_variant_id: this.idProduct,
          num_document: this.document,
          local: this.local,
          company_id: this.option
        })
        this.type_discount = response.data.type_discount
        this.title = this.type_discount === 3
          ? '¿Desea validar el regalo?'
          : this.type_discount === 5
            ? '¿Desea validar el consumo?'
            : 'Ingrese el monto total de la cuenta'
        if (this.type_discount === 3) {
          this.mount = 0
        } else if (this.type_discount === 5) {
          this.mount = response.data.mount_total
        }
      } catch (error) {
        this.errorMessage()
        this.$emit('goBack', 4)
      }
    },
    async getCalculateMount () {
      try {
        if (!this.mount) { return }
        this.$v.$touch()
        if (!this.$v.$invalid) {
          const response = await ValidateService.getCalculateMount({
            num_document: this.document,
            mount: this.mount,
            product_variant_id: this.idProduct,
            company_id: this.option
          })
          this.discount = response.data.discount
        }
      } catch (error) {
        this.discount = 0
        for (let i = 0; i < error.response.data.error.name.length; i++) {
          this.errorMessage(error.response.data.error.name[i])
        }
      }
    },
    async postValidateMount () {
      try {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          if (this.discount === 0 && (this.type_discount === 2 || this.type_discount === 1)) {
            return
          }
          this.isLoading = true
          await ValidateService.postValidateMount({
            num_document: this.document,
            mount: this.mount,
            product_variant_id: this.idProduct,
            local: this.local,
            company_id: this.option
          })
          this.$emit('goBack', 6)
        }
      } catch (error) {
        this.errorMessage(error.response.data.error)
        this.isLoading = false
      }
    },
    errorMessage (message = 'Ocurrió un error') {
      this.$buefy.toast.open({
        message,
        type: 'is-danger'
      })
    }
  }
}
</script>
