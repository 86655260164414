import '@/scss/main.scss'
import Vue from 'vue'
import Buefy from 'buefy'
import router from './router'
import store from './store'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import VueLocalStorage from 'vue-localstorage'
import axios from 'axios'
import setupAxios from './utils/interceptors'
import VueAxios from 'vue-axios'
// Sentry
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

/* Collapse mobile aside menu on route change & set document title from route meta */
const defaultDocumentTitle = 'Validador Weeare'
router.afterEach(to => {
  store.commit('asideMobileStateToggle', false)

  if (to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})

Vue.config.productionTip = false

axios.defaults.baseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://admin.weeare.pe/api/validate/'
    : 'https://admin.beneficios.pe/api/validate/'
Vue.use(VueAxios, axios)
setupAxios()

Vue.use(Buefy)
Vue.use(Vuelidate)
Vue.use(VueLocalStorage)

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://b52338c918ba4dc6815e206f4af28434@o148941.ingest.sentry.io/6007900',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['www.app.weeare.pe', 'app.weeare.pe', /^\//]
      })
    ],
    tracesSampleRate: 1.0
  })
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
