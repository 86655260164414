<template>
  <aside
      v-show="isAsideVisible"
      class="aside is-placed-left is-expanded">
    <aside-tools :is-main-menu="true">
      <div slot="label" class="mt-1">
        <img
          v-show="isDarkModeActive"
          src="@/assets/images/weeare-dark.png"
          alt="Logo Weeare"
          width="130"
        />
        <img
          v-show="!isDarkModeActive"
          src="@/assets/images/weeare.png"
          alt="Logo Weeare"
          width="130"
        />
      </div>
    </aside-tools>
    <div class="menu is-menu-main">
      <template v-for="(menuGroup, index) in menu" >
        <p class="menu-label" v-if="typeof menuGroup === 'string'" :key="index">{{ menuGroup }}</p>
        <aside-menu-list
          v-else
          :key="index"
          :menu="menuGroup"/>
      </template>
    </div>
  </aside>
</template>

<script>
import { mapState } from 'vuex'
import AsideTools from '@/components/AsideTools'
import AsideMenuList from '@/components/AsideMenuList'

export default {
  name: 'AsideMenu',
  components: { AsideMenuList, AsideTools },
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState([
      'isAsideVisible',
      'isDarkModeActive'
    ])
  }
}
</script>
