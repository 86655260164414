<template>
  <nav id="navbar-main" class="navbar is-fixed-top">
    <div class="navbar-brand">
      <a class="navbar-item is-hidden-desktop" @click.prevent="menuToggleMobile">
        <b-icon :icon="menuToggleMobileIcon"/>
      </a>
    </div>
    <div class="navbar-brand is-right mr-1">
      <a class="navbar-item navbar-item-menu-toggle is-hidden-desktop" @click.prevent="menuNavBarToggle">
        <b-icon :icon="menuNavBarToggleIcon" custom-size="default"/>
      </a>
    </div>
    <div class="navbar-menu fadeIn animated faster is-align-items-center" :class="{'is-active':isMenuNavBarActive}">
      <div class="pl-4" v-if="localName">
        <b-icon icon="store" custom-size="default"/>
        {{ localName }}
      </div>
      <div class="navbar-end">
        <nav-bar-menu class="has-divider has-user-avatar">
          <user-avatar/>
          <div class="is-user-name">
            <span>{{ userName }}</span>
          </div>
          <div slot="dropdown" class="navbar-dropdown">
            <router-link to="/profile" class="navbar-item" exact-active-class="is-active">
              <b-icon icon="account" custom-size="default"></b-icon>
              <span>Mi Perfil</span>
            </router-link>
            <router-link to="/" class="navbar-item" exact-active-class="is-active">
              <b-icon icon="book-plus" custom-size="default"></b-icon>
              <span>Nuevo Consumo</span>
            </router-link>
            <router-link to="/select-local" class="navbar-item">
              <b-icon icon="store" custom-size="default"></b-icon>
              <span>Seleccionar Local</span>
            </router-link>
          </div>
        </nav-bar-menu>
        <a class="navbar-item has-divider is-desktop-icon-only" title="Dark mode" @click="darkModeToggle">
          <b-icon :icon="darkModeToggleIcon" custom-size="default"/>
          <span>{{ isDarkModeActive ? 'Modo Claro' : 'Modo Oscuro' }}</span>
        </a>
        <a class="navbar-item is-desktop-icon-only has-text-danger" title="Salir" @click="logout">
          <b-icon icon="logout" custom-size="default"/>
          <span>Cerrar Sesión</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import NavBarMenu from '@/components/NavBarMenu'
import UserAvatar from '@/components/UserAvatar'

export default {
  name: 'NavBar',
  components: {
    UserAvatar,
    NavBarMenu
  },
  data () {
    return {
      isMenuNavBarActive: false
    }
  },
  computed: {
    menuNavBarToggleIcon () {
      return (this.isMenuNavBarActive) ? 'close' : 'dots-vertical'
    },
    menuToggleMobileIcon () {
      return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
    },
    darkModeToggleIcon () {
      return this.isDarkModeActive ? 'white-balance-sunny' : 'weather-night'
    },
    ...mapState([
      'isAsideMobileExpanded',
      'isDarkModeActive',
      'userName',
      'localName'
    ])
  },
  mounted () {
    this.$router.afterEach(() => {
      this.isMenuNavBarActive = false
    })
  },
  methods: {
    menuToggleMobile () {
      this.$store.commit('asideMobileStateToggle')
    },
    menuNavBarToggle () {
      this.isMenuNavBarActive = (!this.isMenuNavBarActive)
    },
    darkModeToggle () {
      this.$localStorage.set('mode', !this.isDarkModeActive ? 'dark' : 'light')
      this.$store.commit('darkModeToggle', this.$localStorage.get('mode') === 'dark')
    },
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>
