<template>
  <card-component
    title="Productos"
    icon="archive"
    class="column"
  >
    <product-skeleton v-if="isLoading" />
    <card-component
      v-else
      v-for="product in products"
      :key="product.id"
      class="m-3"
    >
      <div class="is-flex-tablet is-align-items-center" style="gap: 2rem">
        <div>
          <div style="width: 190px">
            <img
              :src="product.image_crop"
              :alt="product.id"
              class="card"
              width="190px"
            />
          </div>
        </div>
        <div class="is-full">
          <h3 class="has-text-weight-medium is-size-4 mb-3">{{ product.name }}</h3>
          <div v-html="product.description"></div>
          <div class="mt-3 is-flex is-justify-content-space-between is-align-items-center">
            <p>
              Disponible: {{ getStock(product.stock_client, product.has_stock) }}
            </p>
            <b-button type="is-primary" @click.native="goValidate(product.id)">
              Validar
            </b-button>
          </div>
        </div>
      </div>
    </card-component>
    <card-component v-if="locals.length > 0">
      <h5>Este local no cuenta con Descuentos.</h5>
      <h5>Locales con descuento: </h5>
      <ul>
        <li v-for='(local, index) in locals' :key="index">
          {{local.name}}
        </li>
      </ul>
    </card-component>
    <hr>
    <b-button class="is-dark ml-2" @click.native="goBack">
      Regresar
    </b-button>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import ProductSkeleton from '@/components/Validate/Skeleton/ProductSkeleton'
import ValidateService from '@/services/ValidateService'
export default {
  components: {
    CardComponent,
    ProductSkeleton
  },
  props: {
    document: String,
    option: Number
  },
  data () {
    return {
      products: [],
      locals: [],
      isLoading: false,
      stock: ''
    }
  },
  created () {
    this.getProductList()
  },
  methods: {
    goBack () {
      this.$emit('goBack', 0)
    },
    async getProductList () {
      this.isLoading = true
      try {
        const response = await ValidateService.getProductList({
          num_document: this.document,
          company_provider_id: this.option,
          local_id: this.$localStorage.get('local')
        })
        if (!response.data.products.length) {
          this.$emit('goBack', 2)
          return
        }
        this.products = response.data.products
        this.locals = response.data.locals
      } catch (error) {
        this.$buefy.toast.open({
          message: 'Ocurrió un error.',
          type: 'is-danger'
        })
        this.$emit('goBack', 0)
      }
      this.isLoading = false
    },
    getStock (stockClient, hasStock) {
      return `${stockClient - hasStock} de ${stockClient}`
    },
    goValidate (id) {
      this.$emit('validateProduct', {
        step: 5,
        idProduct: id
      })
    }
  }
}
</script>
