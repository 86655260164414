var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"column is-two-fifths"},[_c('card-component',{attrs:{"title":_vm.title,"icon":"account-check"}},[(_vm.type_discount == 1 || _vm.type_discount == 2)?_c('form',[_c('b-field',{attrs:{"label":"Monto en soles S/.","type":{ 'is-danger': _vm.$v.mount.$error },"message":[
          {
            'Requerido. Ingresa un monto válido':
              !_vm.$v.mount.required && _vm.$v.mount.$error
          },
          {
            'Incorrecto. Ingresa un monto válido':
              !_vm.$v.mount.decimal && _vm.$v.mount.$error
          }
        ]}},[_c('b-input',{attrs:{"placeholder":"0","type":"numeric","name":"mount"},nativeOn:{"keyup":function($event){return _vm.getCalculateMount.apply(null, arguments)}},model:{value:(_vm.mount),callback:function ($$v) {_vm.mount=$$v},expression:"mount"}})],1),_c('b-field',{attrs:{"label":"Total a pagar"}},[_vm._v(" S/. "+_vm._s(_vm.discount.toFixed(2))+" ")])],1):(_vm.type_discount == 3)?_c('form',[_c('div',{staticClass:"has-text-centered mt-3"},[_c('b-icon',{attrs:{"icon":"gift","size":"is-large"}})],1)]):(_vm.type_discount == 5)?_c('form',[_c('div',{staticClass:"has-text-centered mt-3"},[_c('b-icon',{attrs:{"icon":"cart-plus","size":"is-large"}})],1)]):_vm._e(),_c('hr'),_c('b-button',{staticClass:"is-primary ml-2",attrs:{"loading":_vm.isLoading},nativeOn:{"click":function($event){return _vm.postValidateMount.apply(null, arguments)}}},[_vm._v(" Validar ")]),_c('b-button',{staticClass:"is-dark ml-2",nativeOn:{"click":function($event){return _vm.goBack.apply(null, arguments)}}},[_vm._v(" Regresar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }