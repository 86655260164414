import axios from 'axios'
class LocalService {
  getLocalList () {
    const response = axios.get('locals-select/')
    return response
  }

  getLocals () {
    const response = axios.get('locals/')
    return response
  }
}

const instance = new LocalService()
export default instance
