<template>
  <section class="is-two-fifths column">
    <card-component
      title="Ingrese los siguientes datos"
      icon="card-text"
      :image="logo"
    >
      <b-field
        label="Email (opcional)"
        :type="{ 'is-danger': $v.data.email.$error }"
        :message="[
          {
            'Requerido. Ingresa tu email':
              !$v.data.email.required && $v.data.email.$error
          },
          {
            'Incorrecto. Ingresa un email válido': !$v.data.email.email && $v.data.email.$error
          }
        ]"
      >
        <b-input
          v-model="data.email"
          placeholder="Ingresa tu email"
          name="email"
        />
      </b-field>
      <b-field
        label="Nombres"
        :type="{ 'is-danger': $v.data.first_name.$error }"
        :message="[
          {
            'Requerido. Ingresa tu nombre':
              !$v.data.first_name.required && $v.data.first_name.$error
          }
        ]"
      >
        <b-input
          v-model="data.first_name"
          placeholder="Ingresa tu nombre"
          name="first_name"
        />
      </b-field>
      <b-field
        label="Apellidos"
        :type="{ 'is-danger': $v.data.last_name.$error }"
        :message="[
          {
            'Requerido. Ingresa tu apellido':
              !$v.data.last_name.required && $v.data.last_name.$error
          }
        ]"
      >
        <b-input
          v-model="data.last_name"
          placeholder="Ingresa tu apellido"
          name="last_name"
        />
      </b-field>
      <b-field label="Documento">
        <b-input :value="document" disabled></b-input>
      </b-field>
      <hr>
      <b-button class="is-primary" :class="{'is-loading':isLoading}" @click.native="validateDocument">
        Continuar
      </b-button>
      <b-button class="is-dark ml-2" @click.native="goBack">
        Regresar
      </b-button>
    </card-component>
  </section>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import CardComponent from '@/components/CardComponent'
import ValidateService from '@/services/ValidateService'
export default {
  components: {
    CardComponent
  },
  props: {
    option: Number,
    document: String,
    logo: String
  },
  data () {
    return {
      isLoading: false,
      data: {
        email: '',
        first_name: '',
        last_name: '',
        num_document: '',
        company_provider: ''
      }
    }
  },
  validations: {
    data: {
      email: { email },
      first_name: { required },
      last_name: { required }
    }
  },
  created () {
    this.data.num_document = this.document
    this.data.company_provider = this.option
  },
  methods: {
    goBack () {
      this.$emit('goBack', 0)
    },
    async validateDocument () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          this.isLoading = true
          await ValidateService.postRegisterUser(this.data)
          this.$buefy.toast.open({
            message: 'Se guardó correctamente',
            type: 'is-success'
          })
          this.$emit('goBack', 4)
        } catch (error) {
          this.$buefy.toast.open({
            message: error.response.data.error,
            type: 'is-danger'
          })
        }
        this.isLoading = false
      }
    }
  }
}
</script>
