<template>
  <section class="has-text-centered column">
    <card-component
      title="No cuenta con beneficios"
      icon="account-remove"
    >
      <p>
        El N° Documento
        <span class="has-text-weight-medium">
          {{ document }}
        </span>
        no cuenta con beneficios.
      </p>
      <span class="has-text-weight-medium">
        Ingresa otro documento o elige otro programa de beneficios.
      </span>
      <hr>
      <b-button class="is-primary ml-2" @click.native="goBack">
        Volver al inicio
      </b-button>
    </card-component>
  </section>
</template>

<script>
import CardComponent from '@/components/CardComponent'
export default {
  components: {
    CardComponent
  },
  props: {
    document: String
  },
  methods: {
    goBack () {
      this.$emit('goBack', 0)
    }
  }
}
</script>
