<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapState(['isDarkModeActive'])
  },
  created () {
    if (this.$localStorage.get('mode')) {
      this.$store.commit('darkModeToggle', this.$localStorage.get('mode') === 'dark')
    } else {
      this.$localStorage.set('mode', this.isDarkModeActive ? 'dark' : 'light')
    }
  }
}
</script>
