import Vue from 'vue'
import {
  SnackbarProgrammatic as Snackbar,
  ToastProgrammatic as Toast
} from 'buefy'
import router from '../router'
import LocalService from '@/services/LocalService'

export default {
  logout () {
    Vue.localStorage.remove('token')
    Vue.localStorage.remove('name')
    Vue.localStorage.remove('company_name')
    Vue.localStorage.remove('email')
    Vue.localStorage.remove('local')
    Snackbar.open({
      message: 'Hasta pronto.',
      queue: false
    })
    router.push({ name: 'login' })
    // localStorage.clear()
  },
  async getLocalList ({ commit, dispatch }) {
    try {
      const response = await LocalService.getLocals()
      commit('setLocalList', response.data)
      dispatch('getCurrentLocal')
    } catch (error) {
      Toast.open({
        message: 'Ocurrió un error',
        type: 'is-danger'
      })
    }
  },

  saveLocal ({ commit, dispatch }, local) {
    Vue.localStorage.set('local', local)
    commit('setLocal', local)
    dispatch('getLocalName')
  },

  getCurrentLocal ({ commit, dispatch }) {
    const local = Vue.localStorage.get('local')
    commit('setLocal', local)
    dispatch('getLocalName')
  },

  getLocalName ({ commit, state }) {
    const local = state.local
    const localList = [...state.localList]
    const localName = localList.filter(item => String(item.id) === String(local))[0]?.name
    commit('setLocalName', localName)
  }
}
