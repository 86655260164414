var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"is-two-fifths column"},[_c('card-component',{attrs:{"title":"Ingrese su N° Documento","icon":"card-text","image":_vm.logo}},[_c('b-field',{attrs:{"label":"DNI","type":{ 'is-danger': _vm.$v.document.$error },"message":[
        {
          'Requerido. Ingresa tu N° Documento':
            !_vm.$v.document.required && _vm.$v.document.$error
        },
        {
          'Incorrecto. Ingresa tu N° Documento':
            !_vm.$v.document.numeric && _vm.$v.document.$error
        }
      ]}},[_c('b-input',{attrs:{"placeholder":"N° Documento","name":"document"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validateDocument.apply(null, arguments)}},model:{value:(_vm.document),callback:function ($$v) {_vm.document=$$v},expression:"document"}})],1),_c('hr'),_c('b-button',{staticClass:"is-primary",attrs:{"loading":_vm.isLoading},nativeOn:{"click":function($event){return _vm.validateDocument.apply(null, arguments)}}},[_vm._v(" Continuar ")]),_c('b-button',{staticClass:"is-dark ml-2",nativeOn:{"click":function($event){return _vm.goBack.apply(null, arguments)}}},[_vm._v(" Regresar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }