import axios from 'axios'
class ValidateService {
  getValidateDocument (params) {
    const response = axios.get('validate-num-document/', {
      params
    })
    return response
  }

  postRegisterUser (data) {
    const response = axios.post('registrar-usuario/', data)
    return response
  }

  getProductList (params) {
    const response = axios.get('products/', {
      params
    })
    return response
  }

  getValidateTypeProduct (params) {
    const response = axios.get('validate-type-product/', {
      params
    })
    return response
  }

  getCalculateMount (params) {
    const response = axios.get('calculate/', {
      params
    })
    return response
  }

  postValidateMount (data) {
    const response = axios.post('validate-mount/', data)
    return response
  }
}

const instance = new ValidateService()
export default instance
