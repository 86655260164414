<template>
  <section class="section is-title-bar">
    <div>
      <ul>
        <li v-for="(title,index) in titleStack" :key="index">{{ title }}</li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TitleBar',
  props: {
    titleStack: {
      type: Array,
      default: () => []
    }
  }
}
</script>
