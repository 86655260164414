<template>
  <div class="columns is-flex-wrap-wrap">
    <div
      class="column is-one-third"
      v-for="(item, index) in 3"
      :key="index"
    >
      <card-component
        class="has-table has-mobile-sort-spaced"
      >
        <div class="columns is-align-items-center">
          <div class="column">
            <figure class="image is-64x64">
              <b-skeleton width="64px" height="64px"></b-skeleton>
            </figure>
          </div>
          <div class="column">
            <b-skeleton width="100%"></b-skeleton>
          </div>
        </div>
      </card-component>
    </div>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
export default {
  components: {
    CardComponent
  }
}
</script>
