import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    /* User */
    userName: null,
    userEmail: null,
    userAvatar: null,
    /* Aside */
    isAsideVisible: true,
    isAsideMobileExpanded: false,
    /* Dark mode */
    isDarkModeActive: false,
    localList: [],
    local: null,
    localName: null
  },
  mutations,
  actions,
  getters
})
