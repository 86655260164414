<template>
  <section class="is-two-fifths column">
    <card-component
      title="Ingrese su N° Documento"
      icon="card-text"
      :image="logo"
    >
      <b-field
        label="DNI"
        :type="{ 'is-danger': $v.document.$error }"
        :message="[
          {
            'Requerido. Ingresa tu N° Documento':
              !$v.document.required && $v.document.$error
          },
          {
            'Incorrecto. Ingresa tu N° Documento':
              !$v.document.numeric && $v.document.$error
          }
        ]"
      >
        <b-input
          v-model="document"
          placeholder="N° Documento"
          name="document"
          @keyup.enter.native="validateDocument"
        />
      </b-field>
      <hr>
      <b-button class="is-primary" :loading="isLoading" @click.native="validateDocument">
        Continuar
      </b-button>
      <b-button class="is-dark ml-2" @click.native="goBack">
        Regresar
      </b-button>
    </card-component>
  </section>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators'
import CardComponent from '@/components/CardComponent'
import ValidateService from '@/services/ValidateService'
export default {
  components: {
    CardComponent
  },
  props: {
    option: Number,
    logo: String
  },
  data () {
    return {
      isLoading: false,
      document: null
    }
  },
  validations: {
    document: { required, numeric }
  },
  methods: {
    goBack () {
      this.$emit('goBack', 0)
    },
    async validateDocument () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          this.isLoading = true
          const response = await ValidateService.getValidateDocument({
            num_document: this.document,
            company_provider_id: this.option
          })
          this.$emit('validateDocument', { response: response.data.response, document: this.document })
        } catch (error) {
          this.$buefy.toast.open({
            message: error.response.data.error,
            type: 'is-danger'
          })
        }
        this.isLoading = false
      }
    }
  }
}
</script>
